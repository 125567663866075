<template>
  <div>
    <PageHeader>
        <template slot="right">
          <div>
            <b-button to="/players/create" variant="primary">
              <i class="uil-plus mr-1"></i>
              {{ $t('buttons.add') }}
            </b-button>
          </div>
        </template>
    </PageHeader>

    <b-overlay :show="isFetchingPlayers">
      <b-card>
        <div v-if="players.dailyLimit" class="text-right mb-3">
          <span class="bg-success px-2 py-1 text-white d-inline-block">
            แจกเครดิตฟรีวันนี้ {{ players.todayFreeCredit.toLocaleString() }} / {{ players.dailyLimit.toLocaleString()}} ยูส
          </span>
        </div>
        <h6>
          {{ `${$t('fields.result')} (${total})` }}
        </h6>
        <b-row align-v="center" class="mb-3">
          <b-col cols="6" md="3" offset-md="6">
            <MasterSelectInput
                v-show="isOwner"
                :value="selectedMaster"
                hide-label
                @update="onMasterIdChange"
            />
          </b-col>
          <b-col cols="6" md="3">
            <AgentsSelectInput
                v-show="isOwner || isMaster"
                :master-id="selectedMaster"
                :value="selectedAgent"
                hide-label
                @update="onAgentIdChange"
            />
          </b-col>
          <b-col cols="12" md="3" offset-md="9">
            <b-form-select v-model="isDeposited" :options="isDepositedOptions"></b-form-select>
          </b-col>
        </b-row>
        <b-row>
          <b-col md="2">
            <b-form-input
                v-model="search"
                :placeholder="`${$t('fields.search')}...(ชื่อผู้ใช้/ รหัสสมาชิก/ ชื่อ) `"
                class="mx-1"
                type="search"
                @keydown.enter="fetchData"
            ></b-form-input>
          </b-col>
          <b-col :md="$allowPermission('agent:manage.user') ? 7 : 8">
            <b-input-group prepend="วันที่สมัคร">
              <b-form-datepicker v-model="startDate" reset-button></b-form-datepicker>
              <b-input-group-append is-text>ถึง</b-input-group-append>
              <b-form-datepicker v-model="endDate" reset-button></b-form-datepicker>
            </b-input-group>
          </b-col>
          <b-col :md="$allowPermission('agent:manage.user') ? 3 : 2" class="text-right">
            <b-row>
              <b-col>
                <b-button block @click="fetchData">
                  <i class="uil uil-search"></i> ค้นหา
                </b-button>
              </b-col>
              <b-col v-if="$allowPermission('agent:export.players')">
                <b-button :disabled="exporting" class="btn btn-info btn-block" @click="exportUsers($event)">
                  <template v-if="exporting">
                    <b-spinner small />
                  </template>
                  <template v-else>
                    <i class="uil uil-download-alt"></i> ดาวน์โหลด
                  </template>
                </b-button>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
      <!-- Table -->
      <b-table :busy="isFetchingPlayers" :fields="fields" :items="playerList" class="mt-3" responsive show-empty>
        <template #cell(#)="data">
          {{ data.index + 1 }}
        </template>
        <template #cell(username)="data">
          <b-link :to="`/players/detail/${data.item.id}`">
            {{ data.value }}
          </b-link>
        </template>
        <template #cell(agent)="data">
          <span v-if="data.value.name">
            {{ data.value.name }}
          </span>
          <b-badge v-else variant="danger">
            <i class="uil uil-times-circle"></i>
            {{ $t('agent.no_agent') }}
          </b-badge>
        </template>
        <template #cell(firstname)="data">
          {{ data.value }}
          {{ data.item.lastname }}
          <b-button
            v-if="data.item.note"
            size="xs"
            variant="warning"
            @click="toggleRemarkModal(data.item)"
          >
          !
          </b-button>
        </template>
        <template #cell(credit)="data">
          {{ data.value | currency }}
        </template>
        <template #cell(holdCredit)="data">
          {{ data.value | currency }}
        </template>
        <template #cell(totalDeposit)="data">
          {{ data.value | currency }}
        </template>
        <template #cell(totalWithdraw)="data">
          {{ data.value | currency }}
        </template>
        <template #cell(createdAt)="data">
          {{ data.value | datetime }}
        </template>
        <template #cell(promotionLockStatus)="data">
          <b-badge  :class="displayPromotionLockColor(data.value)" >
            {{ displayPromotionLock(data.value) }}
          </b-badge>
        </template>
        <template #cell(isBanned)="data">
          <b-badge :variant="data.value ? 'danger' : 'success'">
            {{
              data.value
                ? 'แบน'
                : 'ปกติ'
            }}
          </b-badge>
        </template>
        <template #cell(id)="data">
           <b-button-group size="sm">
             <!-- isFreeCredit
            false = ยังไม่เคยรับ
            ให้แสดงปุ่ม เติมเครดิตฟรี
            true = เคยรับแล้ว
            ให้เปลี่ยนจากเติมเครดิตฟรีสีเขียวเป็นสีแดง "เคยรับเครดิตฟรีแล้ว" และไม่สามารถกดได้ -->
            <b-button
              v-if="$allowPermission('agent:free-credit')"
              :disabled="data.item.isFreeCredit"
              :variant="data.item.isFreeCredit ? (Number(data.item.totalDeposit) > 0 ? 'warning' : 'danger') : 'success'"
              @click="toggleFreeCredit(data.item)"
            >
              {{ data.item.isFreeCredit ? (Number(data.item.totalDeposit) > 0 ? 'มียอดฝากแล้ว' : 'เคยรับเครดิตฟรีแล้ว') : 'เติมเครดิตฟรี' }}

            </b-button>
            <b-dropdown dropleft size="sm" variant="info">
              <template #button-content>
                <i class="uil uil-cog"></i>
              </template>
              <b-dropdown-item
                v-if="$allowPermission('agent:manage.user')"
                :to="`/players/detail/${data.item.id}`">
                <i class="uil uil-edit-alt mr-2"></i>
                {{ $t('buttons.edit') }}
              </b-dropdown-item>
              <b-dropdown-item :href="`/credits/all?search=${data.item.codeName}`">
                <i class="uil uil-list-ul mr-2"></i>
                {{ $t('credits.credit_transaction') }}
              </b-dropdown-item>
              <b-dropdown-item @click="toggleRemarkModal(data.item)">
                <i class="uil uil-comment-info mr-2"></i>
                <span>
                  {{ $t('fields.remark') }}
                </span>
              </b-dropdown-item>
              <b-dropdown-divider></b-dropdown-divider>
              <b-dropdown-item
                v-if="$allowPermission('agent:direct-deposit.user')"
                @click="toggleDirectDepositModal(data.item)"
              >
                <feather
                  class="icon-xs text-success"
                  type="plus-circle"
                ></feather>
                <span class="pl-2 text-success">
                  {{ $t('credits.direct_deposit') }}
                </span>
              </b-dropdown-item>
              <b-dropdown-item
                  v-if="$allowPermission('agent:direct-withdraw.user')"
                  @click="toggleDirectWithdrawModal(data.item)"
              >
                <feather
                  class="icon-xs text-warning"
                  type="minus-circle"
                ></feather>
                <span class="pl-2 text-warning">
                  {{ $t('credits.direct_withdraw') }}
                </span>
              </b-dropdown-item>
              <b-dropdown-item
                  @click="exchangeWallet(data.item)"
              >
                <i class="uil uil-exchange text-info"></i>

                <span class="pl-2 text-info">
                  โยกเครดิต
                </span>
              </b-dropdown-item>
              <b-dropdown-item
                v-if="$allowPermission('agent:set-turn.user') && data.item.promotionLockStatus === null"
                @click="showSetPlayerTurn(data.item)"
              >
                <feather
                  class="icon-xs text-danger"
                  type="lock"
                ></feather>
                <span class="pl-2 text-danger">
                  ใส่เทิร์น
                </span>
              </b-dropdown-item>
              <b-dropdown-item
                v-if="data.item.promotionLockStatus !== null"
                @click="showUnlockPromotionModal(data.item)"
              >
                <feather
                  class="icon-xs text-danger"
                  type="unlock"
                ></feather>
                <span class="pl-2 text-danger">
                  ปลดโปรโมชั่น
                </span>
              </b-dropdown-item>
              <b-dropdown-divider></b-dropdown-divider>
              <b-dropdown-item variant="danger" @click="onBanUser(data.item)">
                <i class="uil uil-ban mr-2"></i>
                <span>
                  {{
                    data.item.isBanned
                      ? $t('user.cancel_ban_user')
                      : $t('user.ban_user')
                  }}
                </span>
              </b-dropdown-item>
            </b-dropdown>
           </b-button-group>
        </template>

        <template #table-busy>
          <div class="text-center my-2">
            <b-spinner class="align-middle"></b-spinner>
          </div>
        </template>
        <template #empty="">
          <p class="text-center text-muted">{{
            $t('messages.nothing_here')
          }}</p>
        </template>
        <template #cell(note)="data">
          <span v-if="data.value.length === 0">-</span>
          <b-badge v-else>{{ data.value }}</b-badge>
        </template>
      </b-table>
        <!-- pagination -->
      <b-row align-v="center" class="mb-3">
        <b-col cols="4">
          <LimitSelect v-model="selectedLimit"/>
        </b-col>
        <b-col>
          <PaginationInput :per-page="limit" :total="total" @update="(val) => currentPage = val"/>
        </b-col>
      </b-row>
      </b-card>
    </b-overlay>
    <DirectWithdrawModal
      ref="directWithdrawTransfer"
      :player="selectedPlayer"
      :player-id="selectedPlayer.id || ''"
      :search="search"
      no-btn
    />
    <DirectDepositModal
      ref="directDepositTransfer"
      :player="selectedPlayer"
      :player-id="selectedPlayer.id || ''"
      :search="search"
      no-attach
      no-btn
      no-promo
      @finish="fetchData"
    />
    <RemarkForm
      :search="search"
      :value="selectedPlayer"
      @finish="closeRemarkModal"
    />
    <FreeCreditModal
      ref="freeCreditModal"
      :player="selectedPlayer"
      :search="search"
      @fetch="fetchData"
    />
    <UnlockPlayerPromotionModal
      @submit="unlockPromotion"
    />
    <SetPlayerTurnModal
      :player="selectedPlayer"
      @fetch-player="fetchData"
    />
  </div>
</template>

<script>
import {mapActions, mapGetters, mapState} from 'vuex'

export default {
  page: {
    title: 'รายการผู้เล่น',
  },
  components: {
    MasterSelectInput: () =>
      import('@components/master/master-select-input'),
    AgentsSelectInput: () =>
      import('@components/agents/agents-select-input'),
    DirectDepositModal: () =>
      import('@components/credits/direct-deposit-player-modal'),
    DirectWithdrawModal: () =>
      import('@components/credits/direct-withdraw-player-modal'),
    RemarkForm: () => import('@components/forms/remark-form'),
    FreeCreditModal: () =>
      import('@components/credits/free-credit-modal'),
    UnlockPlayerPromotionModal: () =>
      import('@components/players/unlock-player-promotion-modal'),
    SetPlayerTurnModal: () =>
      import('@components/players/set-player-turn-modal'),
  },
  data() {
    return {
      currentPage: 1,
      selectedLimit: 20,
      selectedPlayer: { id: ''},
      onSearchTimeout: null,
      fields: [
        '#',
        {
          key: 'codeName',
          label: this.$t('user.prefix'),
        },
        {
          key: 'username',
          label: this.$t('user.username'),
        },
        {
          key: 'firstname',
          label: `${this.$t('user.firstname')} ${this.$t('user.lastname')}`,
        },
        {
          key: 'credit',
          label: this.$t('credits.credit'),
          class: 'text-right',
        },
        {
          key: 'holdCredit',
          label: this.$t('fields.hold_balance'),
          class: 'text-right',
        },
        {
          key: 'totalDeposit',
          label: 'ยอดฝาก',
          class: 'text-right',
        },
        {
          key: 'totalWithdraw',
          label: 'ยอดถอน',
          class: 'text-right',
        },
        {
          key: 'promotionLockStatus',
          label: 'ติดโปรโมชั่น',
        },
        {
          key: 'createdAt',
          label: this.$t('user.createdAt'),
        },
        {
          key: 'isBanned',
          label: this.$t('fields.status'),
          class: 'text-center',
        },
        {
          key: 'id',
          label: '-',
          class: 'text-right',
        },
      ],
      isDepositedOptions: [
        { value: null, 'text': 'ทั้งหมด' },
        { value: true, 'text': 'มียอดฝาก' },
        { value: false, 'text': 'ไม่มียอดฝาก' },
      ],
      search: '',
      startDate: null,
      endDate: null,
      isDeposited: null,
      selectedMaster: '',
      selectedAgent: '',
      exporting: false
    }
  },
  computed: {
    ...mapState({
      isFetchingPlayers: (state) => state.player.isFetchingPlayers,
      isUpdatingPlayerSuccess: (state) => state.player.isUpdatingPlayerSuccess
    }),
    ...mapGetters(['isOwner', 'isMaster', 'players', 'playerDetail']),
    playerList() {
      const {items} = this.players
      return items
    },
    pagination() {
      const {meta} = this.players
      return meta
    },
    limit() {
      return this.pagination.itemsPerPage
    },
    total() {
      return this.pagination.totalItems
    },
  },
  watch: {
    currentPage(val) {
      if(val){
        this.$addPageToLocation(val)
        this.fetchData()
      }
    },
    selectedLimit(val) {
      if(val) {
        this.currentPage > 1 ? this.currentPage = 1 : this.fetchData()
      }
    },
    'selectedPlayer.id'(val){
      if(val){
        this.fetchPlayer(val)
      }
    },
    isUpdatingPlayerSuccess(val){
      if(val === true){
        this.fetchData()
      }
    }
  },
  methods: {
    ...mapActions([
      'fetchPlayers',
      'fetchPlayer',
      'updatePlayer',
      'unlockPlayerPromotion',
      'exchangePlayerWallet',
      'exportPlayers',
      'fetchLatestPromotion'
    ]),
    async exportUsers(e) {
      if(!this.playerList.length) {
        await this.$store.dispatch('notifyError', 'ไม่มีข้อมูลรายชื่อผู้เล่น')
        e.preventDefault()
        return;
      }
      this.exporting = true
      await this.exportPlayers({
        search: this.search,
        agentId: this.selectedAgent,
        masterId: this.selectedMaster,
        isDeposited: this.isDeposited,
        startDate: this.startDate,
        endDate: this.endDate
      })
      this.exporting = false
    },
    fetchData() {
        this.fetchPlayers({
          limit: this.selectedLimit,
          page: this.currentPage,
          search: this.search,
          agentId: this.selectedAgent,
          masterId: this.selectedMaster,
          isDeposited: this.isDeposited,
          startDate: this.startDate,
          endDate: this.endDate
        })
    },
    delaySearch() {
      clearTimeout(this.onSearchTimeout)
      this.onSearchTimeout = setTimeout(this.fetchData, 800)
    },
    onAgentIdChange(agentId) {
      this.selectedAgent = agentId
    },
    onMasterIdChange(masterId) {
      this.selectedMaster = masterId
    },
    toggleRemarkModal(player) {
      this.$bvModal.show('remark-form-modal')
      this.selectedPlayer = player
    },
    closeRemarkModal() {
      this.$bvModal.hide('remark-form-modal')
      this.selectedPlayer = { id: '' }
    },
    toggleDirectDepositModal(player) {
      this.selectedPlayer = player
      this.$refs.directDepositTransfer.toggleModal()
    },
    toggleDirectWithdrawModal(player) {
      this.selectedPlayer = player
      this.$refs.directWithdrawTransfer.toggleModal()
    },
    showSetPlayerTurn(player) {
      this.fetchLatestPromotion(player.id)
      this.selectedPlayer = player
      this.$bvModal.show('set-player-turn-modal')
    },
    showUnlockPromotionModal(player){
      this.selectedPlayer = player
      this.$bvModal.show('unlock-player-promotion-modal')
    },
    unlockPromotion({ removePromotion }) {
      this.unlockPlayerPromotion({
        playerId: this.selectedPlayer.id,
        search: this.search,
        removePromotion
      })
      this.$bvModal.hide('unlock-player-promotion-modal')
    },
    exchangeWallet(player) {
      this.$bvModal
          .msgBoxConfirm('คุณต้องการที่จะโยกเงินจาก กระเป๋าสะสม ไปยัง กระเป๋าหลัก ใช่หรือไม่?', {
            okTitle: `${this.$t('buttons.confirm')}`,
            cancelTitle: `${this.$t('buttons.cancel')}`,
          })
          .then((confirm) => {
            if (confirm) {
              this.exchangePlayerWallet({
                playerId: player.id,
                search: this.search
              })
            }
          })
    },
    toggleFreeCredit(data) {
      this.$refs.freeCreditModal.toggleModal()
      this.selectedPlayer = data
    },
    onBanUser(player) {
      this.selectedPlayer = player
      this.$bvModal
        .msgBoxConfirm(`${this.$t('messages.confirm')}`, {
          okTitle: `${this.$t('buttons.confirm')}`,
          cancelTitle: `${this.$t('buttons.cancel')}`,
        })
        .then((confirm) => {
          if (confirm) {
            this.updatePlayer({
              playerId: player.id,
              data: { 
                isBanned: !player.isBanned,
                firstName: this.playerDetail.profile.firstname || player.firstname,
                lastName: this.playerDetail.profile.lastname || player.lastname,
                bankAccountNumber: this.playerDetail.bankAccount.bankAccountNumber,
                bankCode: this.playerDetail.bankAccount.bankCode,
                },
              search: this.search
            })
          }
        })
    },
    displayPromotionLock(status) {
      if ( status === '' ) {
        status = -1
      } else {
        status = +status
      }

      switch (status) {
        case 0:
          return 'ติดโปรเครดิตฟรี'
        case 1:
          return 'ติดเทิร์นเครดิต'
        case 2:
          return 'ติดเทิร์นโอเวอร์'
        default:
          return 'ไม่ติดโปร';
      }
    },
    displayPromotionLockColor(status) {
      if ( status === '' ) {
        status = -1
      } else {
        status = +status
      }

      switch (status) {
        case 0:
        case 1:
        case 2:
          return 'badge-soft-danger'
        default:
          return 'badge-soft-success';
      }
    },
  },

}
</script>
